.App {
  text-align: center;
  min-height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.waveformgraph-unplayed-graph {
  display: "flex";
  position: absolute;
  z-index: 20 !important;
}

.voice-recorder_controlscontainer {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  background-color: white !important;
}

.voice-recorder_control {
  background-image: none !important;
  background-color: white !important;
  box-shadow: none !important;

}

.overflow-hidden {
  overflow: hidden;
}

.range-slider {
  width: 480px;
  margin: 20px auto 0;
}

/* Yellow Range Slider */
#range-slider-yellow {
  background: url("data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23555' fill-rule='evenodd'%3E%3Cpath d='M0 40L40 0H20L0 20M40 40V20L20 40'/%3E%3C/g%3E%3C/svg%3E") #333;
}

#range-slider-yellow .range-slider__range {
  background: #ffbf00;
  transition: height 0.3s;
}

#range-slider-yellow .range-slider__thumb {
  background: #faa307;
  transition: transform 0.3s;
}

#range-slider-yellow .range-slider__thumb[data-active] {
  transform: translate(-50%, -50%) scale(1.25);
}

#range-slider-yellow .range-slider__range[data-active] {
  height: 16px;
}

/* AB Range Slider */
#range-slider-ab {
  height: 16px;
  background: #4b4d61;
}

#range-slider-ab .range-slider__range {
  background: #ff4141;
}

#range-slider-ab .range-slider__thumb {
  width: 30px;
  height: 30px;
  border-radius: 4px;
}

#range-slider-ab .range-slider__thumb[data-lower] {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='' width='30' height='30' viewBox='0 0 24 24'%3E%3Cpath d='M3,5A2,2 0 0,1 5,3H19A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5C3.89,21 3,20.1 3,19V5M11,7A2,2 0 0,0 9,9V17H11V13H13V17H15V9A2,2 0 0,0 13,7H11M11,9H13V11H11V9Z' /%3E%3C/svg%3E") #ff4141;
}

#range-slider-ab .range-slider__thumb[data-upper] {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 24 24'%3E%3Cpath d='M5,3H19A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V5A2,2 0 0,1 5,3M15,10.5V9A2,2 0 0,0 13,7H9V17H13A2,2 0 0,0 15,15V13.5C15,12.7 14.3,12 13.5,12C14.3,12 15,11.3 15,10.5M13,15H11V13H13V15M13,11H11V9H13V11Z' /%3E%3C/svg%3E") #ff4141;
}

#range-slider-ab .range-slider__thumb[data-lower][data-active] {
  animation: rotate-anti-clockwise 0.9s infinite;
}

#range-slider-ab .range-slider__thumb[data-upper][data-active] {
  animation: rotate-clockwise 0.9s infinite;
}

@keyframes rotate-clockwise {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@keyframes rotate-anti-clockwise {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(-360deg);
  }
}


/* Range Slider as a Single Thumb Slider */
.range-slider__thumb[data-upper="true"] {
  background-color: white !important;
  border: 1px solid black;
}

.single-thumb .range-slider__range {
  background-color: black !important;
  border-radius: 20px;
}

.single-thumb .range-slider__thumb[data-lower] {
  width: 0;
}

.text-tremor-default {
  z-index: 10 !important;
}



/* Custom styling for the slider track */
.range-slider::-webkit-slider-runnable-track {
  background: linear-gradient(to right, black 0%, black var(--progress, 0), gray var(--progress, 0));
  height: 100%;
  border: none;
}

/* Custom styling for the slider thumb */
.range-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  height: 16px;
  width: 16px;
  background: black;
  cursor: pointer;
  margin-top: -4px;
  /* Centers the thumb over the track */
  border: none;
}

.range-slider::-moz-range-track {
  background: linear-gradient(to right, black 0%, black var(--progress, 0), gray var(--progress, 0));
  height: 100%;
  border: none;
}

.range-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  height: 16px;
  width: 16px;
  background: white;
  border: 2px solid black;
  /* Black border */
  border-radius: 50%;
  /* Makes it round */
  cursor: pointer;
  margin-top: -4px;
  /* Adjust this value as needed to center the thumb */
}

/* Custom styling for the slider thumb - Mozilla Firefox */
.range-slider::-moz-range-thumb {
  height: 16px;
  width: 16px;
  background: white;
  /* White background */
  border: 2px solid black;
  /* Black border */
  border-radius: 50%;
  /* Makes it round */
  cursor: pointer;
}

/* Custom styling for the slider thumb - Internet Explorer/Edge */
.range-slider::-ms-thumb {
  height: 16px;
  width: 16px;
  background: white;
  /* White background */
  border: 2px solid black;
  /* Black border */
  border-radius: 50%;
  /* Makes it round */
  cursor: pointer;
}

.input-number::-webkit-inner-spin-button,
.input-number::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input-number {
  -moz-appearance: textfield;
}

.disable-selection {
  user-select: none;
}

.enable-selection {
  user-select: text;
}

.whitespace-pre-wrap {
  white-space: pre-wrap;
  word-wrap: break-word;
}

.no-select {
  user-select: none;
  /* Standard syntax */
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  -moz-user-select: none;
  /* Firefox */
}
